import { createSelector } from '@reduxjs/toolkit';
import { differenceInDays } from 'date-fns';
import isEmpty from 'lodash/isEmpty';

import type { RootState } from '@/store';

import { selectSettings } from '@/ducks/common/settings';
import { currentNYTime } from '@/helpers/util/dateUtil';

import { MNVV_OFFER_LAUNCH_DATE, OFFER_REDEMPTION_DAYS } from './constants';
import { MnvvTypes } from './types';

export const selectMnvvVoyage = (state: RootState) => state.mnvvData.voyage;
export const selectMnvvContent = (state: RootState) => state.mnvvData.content;
export const selectIsMnvv = (state: RootState) => !isEmpty(selectMnvvVoyage(state));
export const selectMnvvChannelId = (state: RootState) => state.mnvvData.channelID;
export const selectMnvvReservationNumber = (state: RootState) => selectMnvvVoyage(state)?.reservationNumber;
export const selectMnvvAgentId = (state: RootState) => selectMnvvVoyage(state)?.agentId;
export const selectMnvvAgencyId = (state: RootState) => selectMnvvVoyage(state)?.agencyId;

export const selectMnvvBookedDate = createSelector(selectSettings, selectMnvvVoyage, (settings, voyage) => {
  const { launchDates, offerRedemptionPeriod = OFFER_REDEMPTION_DAYS } = settings;
  const mnvv = launchDates?.mnvv || MNVV_OFFER_LAUNCH_DATE;

  if (voyage && mnvv) {
    const bookedDate = new Date(voyage.bookedDate);
    const currentDate = currentNYTime();
    const is60DaysOffer = differenceInDays(currentDate, bookedDate) < parseInt(offerRedemptionPeriod.toString(), 10);
    const expired = bookedDate.getTime() < new Date(mnvv).getTime();
    const canRedemptOffer = !expired && is60DaysOffer && voyage.voyageId === MnvvTypes.MNVV;
    if (canRedemptOffer) {
      return voyage.bookedDate;
    }
  }
});

export const selectMnvvDiscount = createSelector(selectMnvvContent, selectMnvvVoyage, (content, voyage) => {
  const key = Object.keys(content?.discounts || {}).find(
    (key) => content?.discounts[key]?.currencyCode === voyage?.currencyCode,
  );

  return key ? content?.discounts[key] : undefined;
});

export const selectMnvvCurrencyCode = createSelector(selectMnvvDiscount, selectMnvvVoyage, (discount, voyage) => {
  return discount?.currencyCode || voyage?.currencyCode;
});

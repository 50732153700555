import { combineReducers } from '@reduxjs/toolkit';

import accessKeys from '@/ducks/accessKeys/accessKeys';
import auth from '@/ducks/auth';
import common from '@/ducks/common';
import cruisesCommon from '@/ducks/cruisesCommon/slice';
import { reducer as filters } from '@/ducks/filters/reducer';
import filtersOptions from '@/ducks/filtersOptions';
import flyout from '@/ducks/flyout/reducers';
import agentData from '@/ducks/fm/slice';
import mnvvData from '@/ducks/mnvv/reducer';
import page from '@/ducks/page';
import chooseVoyageNew from '@/ducks/pages/chooseVoyage/chooseVoyageNew';
import paymentConfirmation from '@/ducks/paymentConfirmation/reducer';
import celebrations from '@/ducks/programs/celebrations/reducers';
import mgm from '@/ducks/programs/mgm/reducers';
import promoBanners from '@/ducks/promoBanners/slice';
import quickSearchItems from '@/ducks/quickSearchItems/reducer';
import travelParty from '@/ducks/travelParty/slice';
import uplift from '@/ducks/uplift/reducer';
import voyageInfo from '@/ducks/voyageInfo';
import warnings from '@/features/warnings/slice';
import accounts from '@/reducers/accounts';
import httpApiErrors from '@/reducers/httpApiErrors';
import promotions from '@/reducers/promotions';
import voyagePlanner from '@/reducers/voyagePlanner';

const rootReducer = combineReducers({
  accessKeys,
  accounts,
  agentData,
  auth, // auth flow
  celebrations,
  chooseVoyageNew,
  common,
  cruisesCommon,
  filters,
  filtersOptions,
  flyout,
  httpApiErrors,
  mgm,
  mnvvData,
  page,
  paymentConfirmation,
  promoBanners,
  promotions,
  quickSearchItems,
  travelParty,
  uplift,
  voyageInfo,
  voyagePlanner,
  warnings,
});

export default rootReducer;

import {
  CABIN_SELECTION_CABIN_CATEGORIES_DATA_CLEAR,
  CABIN_SELECTION_CABIN_CATEGORIES_DATA_ERROR,
  CABIN_SELECTION_CABIN_CATEGORIES_DATA_SUCCESS,
  CABIN_SELECTION_CABIN_CATEGORY_SET_UNAVAILABLE_CABIN,
} from '@/constants/actionTypes';

const defaultState = {
  data: [],
  error: {},
  isLoaded: false,
};

export default function cabinCategoriesReducer(store = defaultState, action = {}) {
  switch (action.type) {
    case CABIN_SELECTION_CABIN_CATEGORIES_DATA_CLEAR:
      return defaultState;
    case CABIN_SELECTION_CABIN_CATEGORY_SET_UNAVAILABLE_CABIN:
      return {
        data: updateCabinCategoriesAvailability(
          action.payload.cabinCategories,
          action.payload.cabinCategoryCode,
          action.payload.cabinTypeCode,
        ),
        error: {},
        isLoaded: true,
      };
    case CABIN_SELECTION_CABIN_CATEGORIES_DATA_SUCCESS:
      return {
        actualData: action.payload,
        data: prepareCabinCategories(action.payload)?.map((category) => customSubMetasGrouping(category)),
        error: {},
        isLoaded: true,
        tracked: [],
      };
    case CABIN_SELECTION_CABIN_CATEGORIES_DATA_ERROR:
      return { data: [], error: action.payload?.message, isLoaded: true };
    default:
      return store;
  }
}

const customSubMetasGrouping = (category) => {
  const grouping = {
    code: 'SEA TERRACE',
    subMetaCode: 'TR',
    subMetaCodes: ['TL', 'TR', 'TC'],
  };
  if (category.code === grouping.code) {
    const rootSubMeta = category?.submetas?.find((subMeta) => subMeta.code === grouping.subMetaCode);

    if (rootSubMeta) {
      const categoryCopy = { ...category };
      const rootSubMetaCopy = { ...rootSubMeta };
      rootSubMetaCopy.cabinTypes = [
        ...categoryCopy.submetas.filter((subMeta) => grouping.subMetaCodes.includes(subMeta.code)),
      ];
      categoryCopy.submetas = [
        rootSubMetaCopy,
        ...categoryCopy.submetas.filter((subMeta) => !grouping.subMetaCodes.includes(subMeta.code)),
      ];

      return categoryCopy;
    }

    return category;
  }

  return category;
};

const updateSubMetasAvailability = (subMetas, cabinTypeCode) =>
  subMetas.map((subMeta) => ({
    ...subMeta,
    cabinTypes: subMeta.cabinTypes.map((cabinType) =>
      cabinType.code === cabinTypeCode ? { ...cabinType, isAvailable: false } : { ...cabinType },
    ),
    isAvailable: subMeta.code === cabinTypeCode ? false : subMeta.isAvailable,
  }));

const updateCabinCategoriesAvailability = (cabinCategories, cabinCategoryCode, cabinTypeCode) =>
  cabinCategories.map((cabinCategory) =>
    cabinCategory.code === cabinCategoryCode
      ? { ...cabinCategory, submetas: updateSubMetasAvailability(cabinCategory.submetas, cabinTypeCode) }
      : cabinCategory,
  );

/**
 * @see https://virginvoyages.atlassian.net/browse/MSH-111937?focusedCommentId=920984
 */
const prepareCabinCategories = (cabinCategories) =>
  cabinCategories?.map((category) => ({
    ...category,
    submetas: category.submetas?.reduce((submetas, item) => {
      if (!item.name) {
        return submetas;
      }

      return [...submetas, item];
    }, []),
  }));

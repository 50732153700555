import differenceWith from 'lodash/differenceWith';
import isEqual from 'lodash/isEqual';
import orderBy from 'lodash/orderBy';

import UIResource from '@/components/UIResource';
import { getAmountTaxAware } from '@/helpers/data/mappers/Summary';

import { getSailingsFromPackages } from './getters';
import { getBestPriceSailing, sortPackageSailings } from './utility';

import classes from '@/components/ChooseVoyage/ViewMoreDates/PricedSailingCard/PricedSailingCard.module.scss';

export const groupPackagesWithSameName = (packages = []) => {
  const filteredPackages = packages.reduce((acc, pckg) => {
    const sameNamePckgs = packages.filter(
      (p) =>
        p.packageName === pckg.packageName && pckg.sailingList?.[0]?.ports.length === p.sailingList?.[0]?.ports.length,
    );
    const allSailings = getSailingsFromPackages(sameNamePckgs);
    // check for best price package and add to list
    if (sameNamePckgs.length >= 2) {
      const bestRatePackage = getBestRatePackage(sameNamePckgs, allSailings);
      const isPortChangeAvailable = checkForPortChange(allSailings[0]?.ports, allSailings);
      if (acc && !acc.find((q) => q.packageName === pckg.packageName)) {
        acc.push({
          ...bestRatePackage,
          isPortChangeAvailable,
          sailingList: allSailings,
          sortedSailings: sortPackageSailings(allSailings, 'date'),
        });
      }
    } else {
      acc.push({ ...pckg, isPortChangeAvailable: false });
    }

    return acc;
  }, []);
  return filteredPackages;
};

const getBestRatePackage = (packages, allSailings) => {
  const packagePricesAreSame = !isEqual(packages.map(({ startingPrice }) => getAmountTaxAware(startingPrice)));
  if (packagePricesAreSame) {
    const bestRateSailing = getBestPriceSailing(allSailings);
    return packages.filter((p) => p.packageCode === bestRateSailing.packageCode)?.[0] || [];
  }
  return orderBy(packages, (p) => getAmountTaxAware(p.startingPrice), 'asc')?.[0] || [];
};

const checkForPortChange = (defaultSailingPort, sailingList) => {
  const defaultPorts = defaultSailingPort.map(({ code }) => code).sort();
  const sailings = sailingList.filter(
    (sailing) => !isEqual(defaultPorts, sailing?.ports.map(({ code }) => code).sort()),
  );
  return sailings.length > 0;
};

export const findSwappedPort = (defaultPackagePorts, ports, isValues = false) => {
  if (!ports?.length) {
    return null;
  }

  const packagePortCodes = defaultPackagePorts?.map(({ code }) => code);
  const sailingPortCodes = ports?.map(({ code }) => code);
  if (isEqual(packagePortCodes.toSorted(), sailingPortCodes.toSorted())) {
    return null;
  }
  const swappedFromPort = defaultPackagePorts?.find(
    (p) => p.code === differenceWith(packagePortCodes, sailingPortCodes, !isEqual)?.[0],
  );
  const swappedToPort = ports.find((p) => p.code === differenceWith(sailingPortCodes, packagePortCodes, !isEqual)?.[0]);

  if (isValues) {
    return { swappedFromPort, swappedToPort };
  }

  return (
    <p className={classes.swappedPortText}>
      <UIResource
        id="Port change strikethrough copy"
        values={{
          'New Port': <b className="toPortText">{swappedToPort?.name}</b>,
          'Strikethrough Previous Port': <span className="strike-trough">{swappedFromPort?.name}</span>,
        }}
      />
    </p>
  );
};

export const getPortsFromDefaultPackOfGrouped = (packageData) =>
  packageData?.sortedSailings?.[0].ports || packageData?.sailingList?.[0].ports;

export default groupPackagesWithSameName;

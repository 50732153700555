import { addYears } from 'date-fns';

import { MNVV_OFFER_LAUNCH_DATE, MNVV_OFFER_VALIDITY_IN_YEARS } from './constants';

export const calculateMaxDate = (inputDate?: string) => {
  const date = new Date(inputDate || MNVV_OFFER_LAUNCH_DATE);
  return addYears(date, MNVV_OFFER_VALIDITY_IN_YEARS);
};

export const getMnvvReservation = (searchParams: URLSearchParams) => {
  const agencyId = searchParams.get('agencyId');
  const agentId = searchParams.get('agentId');
  const channelID = searchParams.get('channelID');
  const reservationNumber = searchParams.get('reservationNumber');

  return {
    agencyId,
    agentId,
    channelID,
    isMNVV: !!(channelID && reservationNumber),
    reservationNumber,
  };
};

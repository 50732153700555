import isEmpty from 'lodash/isEmpty';

import type { RootState } from '@/store';

import { BookingChannelTypes } from '@/constants/settings';

export const selectAgentData = (state: RootState) => state.agentData;
export const selectIsAgencyDataAvailable = (state: RootState) => !isEmpty(selectAgentData(state));

export const selectAgencyCurrencyCode = (state: RootState) => selectAgentData(state)?.agencyCurrencyCode;

export const selectAgencyDetails = (state: RootState) => selectAgentData(state)?.agencyDetails;
export const selectAgencyCurrencies = (state: RootState) => selectAgentData(state)?.agencyDetails.multiCurrencies;
export const selectAgencyId = (state: RootState) => selectAgencyDetails(state)?.agencyId;

export const selectAgentDetails = (state: RootState) => selectAgentData(state)?.agentDetails;
export const selectAgentId = (state: RootState) => selectAgentDetails(state)?.agentId;

export const selectAgentCurrencyCode = (state: RootState) => selectAgentDetails(state)?.currency;

export const selectAgentBookingChannel = (state: RootState) => selectAgentDetails(state)?.bookingChannel;

export const selectFilterAgencyIsAvailable = (state: RootState) => {
  const agencyId = selectAgencyId(state);
  const agentId = selectAgentId(state);
  const bookingChannel = selectAgentBookingChannel(state);

  return Boolean(agencyId || agentId) && bookingChannel === BookingChannelTypes.fmLink;
};

import { createSelector } from '@reduxjs/toolkit';

import type { TSailingData } from '@/infra/types/voyageInfo/sailing';
import type { RootState } from '@/store';
import type { TOptional } from '@/types/common';

import { selectLookupShips } from '@/ducks/common/lookup/selectors';
import { selectCruisesCommonPackagesDataPackages } from '@/ducks/cruisesCommon/selectors';
import { selectVoyageIds } from '@/ducks/filters/selectors';
import { getPackagesForFilteredSailings } from '@/ducks/pages/chooseVoyage/getters';
import {
  getFilteredPackages,
  selectExtendedPackages,
  selectIsMultipleVoyageFilterActive,
  selectMainPackages,
  selectMainSailings,
} from '@/ducks/pages/chooseVoyage/selectors';
import combineSailingData from '@/ducks/pages/summary/helpers/combineSailingData';
import { selectSummary } from '@/ducks/pages/summary/selectors';
import createMemoSelector from '@/helpers/createMemoSelector';

type TPackageType = {
  packageCode: string;
  packageName: string;
};

export const selectSailingData = createMemoSelector(
  selectSummary,
  selectMainSailings,
  selectLookupShips,
  (summary, sailings, ships) =>
    combineSailingData(summary?.sailingData?.data, sailings, ships) as TOptional<TSailingData>,
);

export const selectSailingDataVoyageId = (state: RootState) => selectSailingData(state)?.voyageId;

export const selectSelectedPackage = createSelector(
  [selectCruisesCommonPackagesDataPackages, selectSailingData, (_, searchParams: URLSearchParams) => searchParams],
  (packages, sailingData, searchParams) => {
    const selectedPackageCode = searchParams.get('packageCode') ?? sailingData?.packageCode;

    return packages.find((p) => p.packageCode === selectedPackageCode) || {};
  },
);

export const selectSelectedPackageFromMainPackages = createSelector(
  [
    selectMainPackages,
    selectExtendedPackages,
    selectSailingData,
    selectIsMultipleVoyageFilterActive,
    selectVoyageIds,
    (_, searchParams: URLSearchParams) => searchParams,
  ],
  (packages, filteredPackages, sailingData, isMultipleVoyageFilterActive, voyageIds, searchParams) => {
    const selectedPackageCode = searchParams.get('packageCode') ?? sailingData?.packageCode;
    const currentPackage = packages?.find((p: TPackageType) => p.packageCode === selectedPackageCode);
    const selectedPackage = filteredPackages?.find(
      (pack: TPackageType) => pack.packageName === currentPackage?.packageName,
    );

    if (isMultipleVoyageFilterActive && selectedPackage) {
      const filteredSailings = selectedPackage?.sailingList?.filter((sailing) => voyageIds.indexOf(sailing.id) > -1);
      return getPackagesForFilteredSailings(filteredSailings, packages)?.[0];
    }

    return selectedPackage;
  },
);

export const selectIsSailingDataLoaded = createSelector(
  [(state) => state, selectSailingData, (_, searchParams: URLSearchParams) => searchParams],
  (state, sailingData, searchParams) => {
    const packageCode = searchParams.get('packageCode');
    const defaultVoyageId = selectBestPriceSailingByPackageCode(state, packageCode)?.id;
    const voyageId = searchParams.get('voyageId') || defaultVoyageId;
    return sailingData?.voyageId === voyageId && sailingData?.packageCode === packageCode;
  },
);

export const selectBestPriceSailingByPackageCode = createSelector(
  [getFilteredPackages, (_, packageCode?: null | string) => packageCode],
  (filteredPackages, packageCode) => {
    const selectedPackage = filteredPackages?.find((p: TPackageType) => p.packageCode === packageCode);
    return selectedPackage?.bestPriceSailing;
  },
);

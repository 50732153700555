import { type PayloadAction, createReducer } from '@reduxjs/toolkit';

import type { TUserProfile } from '@/infra/types/user';

import { fetchProfileDataAction } from '@/actions/accounts/dashboard';
import { CLEAR_LOGGED_IN_SESSION_DATA } from '@/constants/actionTypes';

export type TProfileReducer = {
  data: Partial<TUserProfile>;
  error: boolean | object;
  isLoading: boolean;
};

const initialState: TProfileReducer = { data: {}, error: {}, isLoading: false };
const profileReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(CLEAR_LOGGED_IN_SESSION_DATA, () => initialState)
    .addCase(fetchProfileDataAction.pending, () => ({
      ...initialState,
      isLoading: true,
    }))
    .addCase(fetchProfileDataAction.fulfilled, (_, { payload }: PayloadAction<TUserProfile>) => ({
      ...initialState,
      data: payload,
    }))
    .addCase(fetchProfileDataAction.rejected, () => ({
      ...initialState,
      error: true,
    })),
);

export default profileReducer;

import { createSelector } from '@reduxjs/toolkit';

import type { TUserNotification } from '@/infra/types/user';
import type { RootState } from '@/store';

export const selectAccounts = (state: RootState) => state?.accounts;

export const selectProfile = createSelector(selectAccounts, (accounts) => accounts?.profile?.data);

export const selectProfileEmailNotifications = createSelector(selectAccounts, (accounts) => {
  const searchKey: keyof TUserNotification = 'IsOptInForEmail';
  return accounts?.profile?.data?.userNotifications?.filter((notification) =>
    Object.prototype.hasOwnProperty.call(notification, searchKey),
  );
});

export const selectIsProfileLoading = createSelector(selectAccounts, (accounts) => accounts?.profile?.isLoading);
